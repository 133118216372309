.k-colorpalette {

    table > th, td {
        border-radius: 8px;
    }

    table tr:last-child td:last-child {
        background-color: transparent;
        background: conic-gradient(white 0.25turn, Silver 0.25turn 0.5turn, white 0.5turn 0.75turn, Silver 0.75turn) top left / 50% 50% repeat;
    }

    .k-colorpalette-table {
        border-spacing: 8px;
        border-collapse: separate;

        .k-colorpalette-tile.k-state-selected {
            border: #0094ff 2px solid;
            box-shadow: none;
        }
    }
}
