﻿/* colors */
$color-primary: #1db3dd;
$color-inactive: #4840408a;

$button-color-primary: #1db3dd;

$navigation-img-icon-color: #4840408a;
$navigation-img-icon-disabled-color: #ab9e9e8a;

$clicked-zone-background-color: #eff2f4;

$grid-text-not-selected-color: #858889;
$grid-text-selected-color: #000000;
$grid-header-row-color: #686868;
$grid-header-row-background-color: #e8e8e8;
$grid-selected-row-background-color: #0094ff;

$snackbar-success-background-color: green;
$snackbar-error-background-color: red;

$no-auscultation-message-content-background-color: #e8e8e8;
$text-color: #404040;

$dialog-border-bottom-color: #bdcfd8;

$summary-drawer-label-color: #004568;
$summary-drawer-grid-header-background-color: #f6f6fe;
$summary-drawer-btn-export-background-color: #1db3dd;
$summary-drawer-grid-selected-row-background-color: #1db3dd;
$input-icon-color: #858889;

$projects-section-background-color: rgba(255, 255, 255, 0.4);
$project-item-card-box-shadow: -4px 8px 24px -4px rgba(0, 0, 0, 0.16);
$project-label-background-color: rgba(0, 69, 104, 0.7);

$nav-menu-background-color: #004568;
$nav-menu-active-link-color: #FAD000;

$drawer-border-right-color: #DEEBF1;
$drawer-border-title-color: #004568;

$good-color: #39C05F;
$to-monitor-color: #FA9E00;
$bad-color: #FA0000;
$undetermined-color: #fff;
$unfiltered-section-color: #c4c9c7;
$selected-section-color: gold;
$enlightened-area-color: #009CCC;

$arrachement-anomaly-color: #634B72;
$faiençage-anomaly-color: #9747FF;
$feu_de_circulation-anomaly-color: #000000;
$fissure_longitudinale-anomaly-color: #FAD000;
$fissure_transversale-anomaly-color: #78D4ED;
$joint_longitudinal-anomaly-color: #000000;
$nid_de_poule-anomaly-color: #584E45;
$passage_pieton-anomaly-color: #000000;
$pontage_anomaly-color: #000000;
$reparation_anomaly-color: #CD78C5;
$tampon_anomaly-color: #000000;
$tranchee_transversale-anomaly-color: #000000;
$affaissement_de_rive-anomaly-color: #61B1B9;
$bordure_trottoir-anomaly-color: #C0CE2E;
$emergence_sur_chaussee-anomaly-color: #89DCA0;
$faiençage_ponte-anomaly-color: #FFC766;
$fiss_long_ponte-anomaly-color: #A4186D;
$fiss_trans_ponte-anomaly-color: #FF6666;
$peignage-anomaly-color: #1DB3DD;
$ressuage-anomaly-color: #3363DC;
$tranchee-anomaly-color: #B85B27;
$mixed_anomalies_color: #848788;

$work-to-do-color: #FA9E00;
$work-done-color: #1DB3DD;

$map-filter-button-selected-background-color: #1DB3DD;
/* variables */
$header-height: 48px;
$details-drawer-margin-from-header: 1px;
$header-clicked-zone-width: 100px;
$clicked-zone-content-width: 320px;
$user-admin-padding: 15px;
$users-grid-label-height: 24px;
$user-grid-width: 100%;
$user-grid-margin-top: 30px;
$btn-action-margin-top-bottom: 20px;
$btn-action-height: 36px;
$user-profile-tooltip: 170px;
$search-project-zone-height: 230px;
$auscultation-summary-content-input-search-roads-height: 40px;

$auscultation-summary-content-padding: 10px;
$auscultation-summary-content-title-margin-bottom: 10px;
$auscultation-summary-content-title-height: 46px;
$auscultation-summary-content-hr-height: 2px;
$auscultation-summary-content-hr-margin-top-bottom: 10px;
$auscultation-summary-content-only-selection-checkbox-height: 42px;
$auscultation-summary-content-text-height: 14px;
$auscultation-summary-content-export-icons-margin-top: 10px;
$auscultation-summary-content-export-icons-height: 37px;
$auscultation-summary-content-average-score-slider-height: 65px;

$help-dialog-width: 490px;
$user-dialog-width: 586px;
$user-dialog-height: 348px;

$snackbar-background-success: #43a047;
$snackbar-background-warning: #ffa000;
$snackbar-background-error: #d32f2f;

// nouvel design
$font-family: 'open sans';

$nav-menu-width: 110px;
$nav-menu-height: 75px;
$nav-menu-width: 95px;
$nav-menu-padding-top: 16px;
$nav-menu-padding-bottom: 16px;
$nav-menu-padding-left: 8px;
$nav-menu-padding-right: 4px;

$scenario-view-header-height: 26px;
$scenario-view-header-padding: 20px;

$scenario-selector-search-input-height: 32px;
$scenario-selector-search-input-margin-bottom: 15px;

$scenario-selector-scenarios-list-margin-bottom: 15px;
$scenario-selector-btn-add-scenario-height: 32px;

$scenario-management-filters-height: 32px;
$scenario-management-filters-margin-bottom: 15px;
$scenario-management-scenario-areas-list-margin-bottom: 15px;
$scenario-management-add-and-export-scenario-area-height: 32px;

$opened-auscultation-selector-with-roads-drawer-width: 468px;
$closed-drawer-width: 55px;
$drawer-border-right: 1px;

$opened-scenarios-selector-width: 400px;

$images-content-padding: 8px;
$images-navigation-button-width: 32px;
$images-navigation-button-padding: 8px;

$labeled-images-height: 289px;

$road-condition-map-content-width-with-opened-auscultations-selector-with-roads-drawer: calc(100vw - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - (#{$drawer-border-right} * 2));
$road-condition-map-content-width-with-closed-auscultations-selector-with-roads-drawer: calc(100vw - #{$closed-drawer-width} - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - (#{$drawer-border-right} * 2));
$road-condition-map-content-width-without-drawer: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - (#{$drawer-border-right} * 2));
$road-condition-map-content-height-with-labeled-images-content-opened: calc(100vh - $labeled-images-height);

$opened-drawer-content-padding-height-bottom: 8px;
$opened-drawer-content-padding-left-right: 16px;
$opened-drawer-content-header-height: 40px;
$opened-drawer-content-header-margin-bottom: 15px;
$opened-drawer-content-statement-dates-height: 32px;
$opened-drawer-content-statement-dates-margin-bottom: 15px;
$opened-drawer-content-roads-search-height: 60px;
$opened-drawer-content-roads-search-margin-top: 15px;
$opened-drawer-content-total-covered-height: 32px;
$opened-drawer-content-total-covered-margin-top: 15px;
$opened-drawer-content-selection-condition-margin-bottom: 10px;
$opened-drawer-content-selection-condition-margin-top: 8px;
$opened-drawer-content-selection-condition-height: 69px;
$opened-drawer-content-export-margin-top: 15px;
$opened-drawer-content-export-height: 34px;
$summary-drawer-grid-margin-bottom: 15px;

$road-works-information-height: 100vh;
$road-works-information-widht: 500px;
$road-works-information-padding: 10px;
$road-works-information-header-height: 40px;
$road-works-information-maintenance-areas-padding-top-bottom: 10px;

$hr-border: 1px;

$map-filters-content-padding: 8px;
$road-works-map-filter-button-top: 143px;
$roads-condition-map-filter-button-top: 108px;

$label-score-grid-height-gap-from-window: $opened-drawer-content-padding-height-bottom + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-header-height + $opened-drawer-content-header-margin-bottom + $opened-drawer-content-statement-dates-height + $opened-drawer-content-statement-dates-margin-bottom + 1px + $opened-drawer-content-roads-search-height + $opened-drawer-content-roads-search-margin-top + $summary-drawer-grid-margin-bottom + 1px + $opened-drawer-content-total-covered-height + $opened-drawer-content-total-covered-margin-top + $opened-drawer-content-selection-condition-margin-top + $opened-drawer-content-selection-condition-margin-bottom + $opened-drawer-content-selection-condition-height + 1px + $opened-drawer-content-export-height + $opened-drawer-content-export-margin-top;
$client-label-score-grid-height-gap-from-window: $opened-drawer-content-padding-height-bottom + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-header-height + $opened-drawer-content-header-margin-bottom + $opened-drawer-content-statement-dates-height + $opened-drawer-content-statement-dates-margin-bottom + 1px + $opened-drawer-content-roads-search-height + $opened-drawer-content-roads-search-margin-top + $summary-drawer-grid-margin-bottom + 1px + $opened-drawer-content-total-covered-height + $opened-drawer-content-total-covered-margin-top + $opened-drawer-content-selection-condition-margin-top + $opened-drawer-content-selection-condition-margin-bottom + $opened-drawer-content-selection-condition-height;
$scenario-label-score-grid-height-gap-from-window: $scenario-view-header-height + $scenario-view-header-padding + 1px + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-header-height + $opened-drawer-content-header-margin-bottom + $opened-drawer-content-statement-dates-height + $opened-drawer-content-statement-dates-margin-bottom + 1px + $opened-drawer-content-roads-search-height + $opened-drawer-content-roads-search-margin-top + $summary-drawer-grid-margin-bottom + 1px + $opened-drawer-content-total-covered-height + $opened-drawer-content-total-covered-margin-top + $opened-drawer-content-selection-condition-margin-top + $opened-drawer-content-selection-condition-margin-bottom + $opened-drawer-content-selection-condition-height + 15px;

$scenarios-grid-offset: $scenario-view-header-height + $scenario-view-header-padding + 1px + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-header-height + $opened-drawer-content-header-margin-bottom + $scenario-selector-search-input-height + $scenario-selector-search-input-margin-bottom + $scenario-selector-scenarios-list-margin-bottom + $scenario-selector-btn-add-scenario-height + 20px;

$scenario-areas-grid-offset: $scenario-view-header-height + $scenario-view-header-padding + 1px + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-header-height + $opened-drawer-content-header-margin-bottom + $scenario-management-filters-height + $scenario-management-filters-margin-bottom + $scenario-management-scenario-areas-list-margin-bottom + $scenario-management-add-and-export-scenario-area-height + 20px;

$images-anomaly-score-content-height: 65px;
$images-anomalies-list-height: 169px;

$scenario-map-height: calc(100vh - #{$scenario-view-header-height} - #{$scenario-view-header-padding} - #{$scenario-view-header-padding});
$scenario-map-height-with-labeled-images-drawer-opened: calc(100vh - #{$scenario-view-header-height} - #{$scenario-view-header-padding} - #{$scenario-view-header-padding} - #{$labeled-images-height});

$scenario-map-width-with-closed-drawers: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$drawer-border-right});
$scenario-map-width-with-opened-drawers: calc((100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$opened-scenarios-selector-width} - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$drawer-border-right}) / 2);

$scenario-map-width-with-only-opened-scenarios-selector-drawer: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$opened-scenarios-selector-width} - #{$drawer-border-right});
$scenario-map-width-with-only-opened-scenario-management-drawer: calc((100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$drawer-border-right}) / 2);
$scenario-map-width-with-only-opened-auscultations-selector-with-roads-drawer: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$drawer-border-right});

$scenario-map-width-with-opened-scenarios-selector-and-opened-scenario-management-dawers: calc((100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$opened-scenarios-selector-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$drawer-border-right}) / 2);
$scenario-map-width-with-opened-scenarios-selector-and-opened-auscultations-selector-with-roads-drawers: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$opened-scenarios-selector-width} - #{$drawer-border-right} - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$drawer-border-right});
$scenario-map-width-with-opened-scenario-management-and-opened-auscultations-selector-with-roads-drawers: calc((100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right}) / 2);

$scenario-management-drawer-width-with-closed-drawers: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$drawer-border-right});
$scenario-management-drawer-width-with-opened-drawers: calc((100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$opened-scenarios-selector-width} - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$drawer-border-right}) / 2);

$scenario-management-drawer-width-with-only-opened-scenarios-selector-drawer: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$opened-scenarios-selector-width} - #{$drawer-border-right});
$scenario-management-drawer-width-with-only-opened-map-drawer: calc((100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$drawer-border-right}) / 2);
$scenario-management-drawer-width-with-only-opened-auscultations-selector-with-roads-drawer: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$drawer-border-right});

$scenario-management-drawer-width-with-opened-scenarios-selector-and-opened-map-dawers: calc((100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$opened-scenarios-selector-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$drawer-border-right}) / 2);
$scenario-management-drawer-width-with-opened-scenarios-selector-and-opened-auscultations-selector-with-roads-drawers: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$opened-scenarios-selector-width} - #{$drawer-border-right} - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$drawer-border-right});
$scenario-management-drawer-width-with-opened-map-and-opened-auscultations-selector-with-roads-drawers: calc((100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - #{$closed-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right} - #{$opened-auscultation-selector-with-roads-drawer-width} - #{$drawer-border-right} - #{$drawer-border-right}) / 2);

:export {
    labelScoreGridHeightGapFromWindow: $label-score-grid-height-gap-from-window;
    clientLabelScoreGridHeightGapFromWindow: $client-label-score-grid-height-gap-from-window;
    scenarioLabelScoreGridHeightGapFromWindow: $scenario-label-score-grid-height-gap-from-window;
    scenariosGridOffset: $scenarios-grid-offset;
    scenarioAreasGridOffset: $scenario-areas-grid-offset;
    roadConditionMapContentWidthWithOpenedDrawer: $road-condition-map-content-width-with-opened-auscultations-selector-with-roads-drawer;
    roadConditionMapContentWidthWithClosedDrawer: $road-condition-map-content-width-with-closed-auscultations-selector-with-roads-drawer;
    roadConditionMapContentWidthWithoutDrawer: $road-condition-map-content-width-without-drawer;
    roadConditionMapContentHeightWithLabeledImagesContentOpened: $road-condition-map-content-height-with-labeled-images-content-opened;
    goodColor: $good-color;
    toMonitorColor: $to-monitor-color;
    badColor: $bad-color;
    unfilteredSectionColor: $unfiltered-section-color;
    selectedSectionColor: $selected-section-color;
    enlightenedAreaColor: $enlightened-area-color;
    scenarioMapHeight: $scenario-map-height;
    scenarioMapHeightWithLabeledImagesDrawerOpened: $scenario-map-height-with-labeled-images-drawer-opened;
    scenarioMapWidthWithClosedDrawers: $scenario-map-width-with-closed-drawers;
    scenarioMapWidthWithOpenedDrawers: $scenario-map-width-with-opened-drawers;
    scenarioMapWidthWithOnlyOpenedScenariosSelectorDrawer: $scenario-map-width-with-only-opened-scenarios-selector-drawer;
    scenarioMapWidthWithOnlyOpenedScenarioManagementDrawer: $scenario-map-width-with-only-opened-scenario-management-drawer;
    scenarioMapWidthWithOnlyOpenedAuscultationsSelectorWithRoadsDrawer: $scenario-map-width-with-only-opened-auscultations-selector-with-roads-drawer;
    scenarioMapWidthWithOpenedScenariosSelectorAndOpenedScenarioManagementDawers: $scenario-map-width-with-opened-scenarios-selector-and-opened-scenario-management-dawers;
    scenarioMapWidthWithOpenedScenariosSelectorAndOpenedAuscultationsSelectorWithRoadsDrawers: $scenario-map-width-with-opened-scenarios-selector-and-opened-auscultations-selector-with-roads-drawers;
    scenarioMapWidthWithOpenedScenarioManagementAndOpenedAuscultationSelectorWithRoadsDrawers: $scenario-map-width-with-opened-scenario-management-and-opened-auscultations-selector-with-roads-drawers;
    arrachementAnomalyColor: $arrachement-anomaly-color;
    faiençageAnomalyColor: $faiençage-anomaly-color;
    feuDeCirculationAnomalyColor: $feu_de_circulation-anomaly-color;
    fissureLongitudinaleAnomalyColor: $fissure_longitudinale-anomaly-color;
    fissureTransversaleAnomalyColor: $fissure_transversale-anomaly-color;
    jointLongitudinalAnomalyColor: $joint_longitudinal-anomaly-color;
    nidDePouleAnomalyColor: $nid_de_poule-anomaly-color;
    passagePietonAnomalyColor: $passage_pieton-anomaly-color;
    pontageAnomalyColor: $pontage_anomaly-color;
    reparationAnomalyColor: $reparation_anomaly-color;
    tamponAnomalyColor: $tampon_anomaly-color;
    trancheeTransversaleAnomalyColor: $tranchee_transversale-anomaly-color;
    affaissementDeRiveAnomalyColor: $affaissement_de_rive-anomaly-color;
    bordureTrottoirAnomalyColor: $bordure_trottoir-anomaly-color;
    emergenceSurChausseeAnomalyColor: $emergence_sur_chaussee-anomaly-color;
    faiençagePonteAnomalyColor: $faiençage_ponte-anomaly-color;
    fissLongPonteAnomalyColor: $fiss_long_ponte-anomaly-color;
    fissTransPonteAnomalyColor: $fiss_trans_ponte-anomaly-color;
    peignageAnomalyColor: $peignage-anomaly-color;
    ressuageAnomalyColor: $ressuage-anomaly-color;
    trancheeAanomalyColor: $tranchee-anomaly-color;
    mixedAnomaliesColor: $mixed_anomalies_color;
}
